.hide {
  background-color: transparent;
}

.logo {
  width: 100px;
}

.banner2 {
  background-color: #0d1028;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  width: 100%;
}

.banner-content{
  padding: 13% 0%;
}

/* Gaya navbar default */
.navbar {
transition: background-color 0.3s ease;
}

/* Gaya navbar ketika di-scroll */
.navbar-scrolled {
background-color: black !important;
}

/* ================================ */

/* Untuk ponsel kecil */
@media (width< 768px) {
.main-banner-content h1 {
  color: #ffffff;
  margin: 0;
  font-weight: 900;
  font-size: 30px;
}

/* .main-banner {
  height: 100vh;
  position: relative;
  z-index: -1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
  
} */
/* .main-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
} */

/* .slideshow-banner::before {
  z-index: 1;
}
.slideshow-banner .main-banner-content {
  margin-top: 50px;
  
}
.slideshow-banner .main-banner-content::before {
  display: none;
}
.slideshow-banner .slideshow {
  z-index: -1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.slideshow-banner .slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  backface-visibility: hidden;
  animation: imageAnimation 24s linear infinite 0s;
}
.slideshow-banner .slideshow li:nth-child(2) span {
  animation-delay: 6s;
}
.slideshow-banner .slideshow li:nth-child(3) span {
  animation-delay: 12s;
}
.slideshow-banner .slideshow li:nth-child(4) span {
  animation-delay: 18s;
}
.slideshow-banner .event-countdown {
  position: relative;
  right: 0;
  bottom: 0;
  text-align: left;
}
.slideshow-banner .event-countdown #days {
  margin-left: 0;
}

.video-banner {
  overflow: hidden;
}
.video-banner .video-background {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  z-index: -2;
} */

.main-banner-content {
  max-width: 850px;
  position: relative;
  z-index: 1;
  padding-bottom: 80px;
}
/* .main-banner-content::before {
  content: "";
  position: absolute;
  top: -75px;
  width: 100%;
  max-width: 380px;
  text-align: center;
  height: 500px;
  left: 18%;
  margin-left: -190px;
  z-index: -1;
} */

.main-banner-content h1 span {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
  background-size: 100px 1.2em !important;
}
.main-banner-content h1 b:nth-of-type(1) {
  color: #efa506;
}
.main-banner-content h1 b:nth-of-type(2) {
  color: #FF2D55;
}
.main-banner-content h1 b:nth-of-type(3) {
  color: #ffffff;
  animation: colorchange 20s;
}
.main-banner-content h1 b:nth-of-type(4) {
  color: #00acee;
}
.main-banner-content ul {
  padding: 0;
  margin: 25px 0 40px;
  list-style-type: none;
}
.main-banner-content ul li {
  color: #fff;
  margin-right: 20px;
  font-size: 11px;
  display: inline-block;
  padding-left: 20px;
}
.main-banner-content ul li i {
  margin-right: 3px;
  color: #FF2D55;
}
.main-banner-content ul li:last-child {
  margin-right: 0;
}
.main-banner-content p {
  color: #efa506;
  font-size: 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.main-banner-content p span {
  color: #00acee;
}

.main-banner-content.banner-content-center {
  margin: 50px auto 0;
  text-align: center;
}

.home-slides .swiper-button-next, .home-slides .swiper-button-prev {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #00acee;
  color: #ffffff;
  transition: 0.5s;
  opacity: 0.7;
}
.home-slides .swiper-button-next::after, .home-slides .swiper-button-prev::after {
  font-size: 25px;
}
.home-slides .swiper-button-next:hover, .home-slides .swiper-button-prev:hover {
  background-color: #FF2D55;
  color: #ffffff;
}
}

/* Untuk tablet */
@media (width> 768px) {

.main-banner-content h1 {
color: #ffffff;
margin: 0;
font-weight: 900;
font-size: 65px;
}

.main-banner {
height: 100vh;
position: relative;
z-index: -1;
background-position: center center;
background-size: cover;
background-repeat: no-repeat;
opacity: 0.9;

}
.main-banner::before {
content: "";
position: absolute;
left: 0;
top: 0;
z-index: 1;
width: 100%;
height: 100%;
/* background: #6f0000;
opacity: 0.8;
mix-blend-mode: multiply; */
}

.slideshow-banner::before {
z-index: 1;
}
.slideshow-banner .main-banner-content {
margin-top: 50px;

}
.slideshow-banner .main-banner-content::before {
display: none;
}
.slideshow-banner .slideshow {
z-index: -1;
list-style-type: none;
padding: 0;
margin: 0;
}
.slideshow-banner .slideshow li span {
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
color: transparent;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
opacity: 0;
z-index: 0;
backface-visibility: hidden;
animation: imageAnimation 24s linear infinite 0s;
}
.slideshow-banner .slideshow li:nth-child(2) span {
animation-delay: 6s;
}
.slideshow-banner .slideshow li:nth-child(3) span {
animation-delay: 12s;
}
.slideshow-banner .slideshow li:nth-child(4) span {
animation-delay: 18s;
}
.slideshow-banner .event-countdown {
position: relative;
right: 0;
bottom: 0;
text-align: left;
}
.slideshow-banner .event-countdown #days {
margin-left: 0;
}

.video-banner {
overflow: hidden;
}
.video-banner .video-background {
position: absolute;
left: 0;
top: 0;
width: auto;
height: auto;
min-height: 100%;
min-width: 100%;
z-index: -2;
}

.main-banner-content {
max-width: 850px;
position: relative;
z-index: 1;
padding-bottom: 200px;
}
.main-banner-content::before {
content: "";
position: absolute;
top: -75px;
width: 100%;
max-width: 380px;
text-align: center;
height: 500px;
left: 18%;
margin-left: -190px;
z-index: -1;
/* border: 18px solid rgba(104, 89, 222, 0.24); */
/* box-sizing: border-box; */
/* box-shadow: 0 60px 40px -40px rgba(0, 0, 0, 0.3); */
}

.main-banner-content h1 span {
background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
background-size: 100px 1.2em !important;
}
.main-banner-content h1 b:nth-of-type(1) {
color: #efa506;
}
.main-banner-content h1 b:nth-of-type(2) {
color: #FF2D55;
}
.main-banner-content h1 b:nth-of-type(3) {
color: #ffffff;
animation: colorchange 20s;
}
.main-banner-content h1 b:nth-of-type(4) {
color: #00acee;
}
.main-banner-content ul {
padding: 0;
margin: 25px 0 40px;
list-style-type: none;
}
.main-banner-content ul li {
color: #fff;
margin-right: 20px;
font-size: 17px;
display: inline-block;
}
.main-banner-content ul li i {
margin-right: 3px;
color: #FF2D55;
}
.main-banner-content ul li:last-child {
margin-right: 0;
}
.main-banner-content p {
color: #efa506;
font-size: 22px;
letter-spacing: 1px;
margin-bottom: 10px;
}
.main-banner-content p span {
color: #00acee;
}
.main-banner-content .button-box a {
display: inline-block;
}
.main-banner-content .button-box .video-btn {
margin-left: 30px;
color: #fff;
font-weight: 500;
cursor: pointer;
}
.main-banner-content .button-box .video-btn i {
width: 50px;
display: inline-block;
height: 50px;
line-height: 51px;
text-align: center;
background-color: #00acee;
border-radius: 50%;
font-size: 17px;
margin-right: 8px;
z-index: 1;
position: relative;
transition: 0.5s;
}
.main-banner-content .button-box .video-btn i::after {
z-index: -1;
content: "";
position: absolute;
left: 0;
top: 0;
width: 50px;
height: 50px;
animation: ripple 1.6s ease-out infinite;
animation-delay: 3s;
opacity: 0;
border-radius: 50%;
background: rgba(51, 102, 204, 0.5);
}
.main-banner-content .button-box .video-btn:hover, .main-banner-content .button-box .video-btn:focus {
color: #00acee;
}
.main-banner-content .button-box .video-btn:hover i, .main-banner-content .button-box .video-btn:focus i {
background-color: #FF2D55;
color: #ffffff;
}
.main-banner-content.banner-content-center {
margin: 50px auto 0;
text-align: center;
}

.countdown-area {
padding: 50px 0;
background: #ffc341;
}
.countdown-area .event-countdown {
position: relative;
right: 0;
bottom: 0;
text-align: center;
}
.countdown-area .event-countdown #timer div {
margin: 0 55px;
}
.countdown-area .event-countdown #timer div#seconds {
color: #FF2D55;
}

.event-countdown {
position: absolute;
right: 10%;
bottom: 20px;
text-align: right;
}
.event-countdown #timer div {
display: inline-block;
font-size: 55px;
font-weight: 700;
color: #ffffff;
margin: 0 35px;
position: relative;
}
.event-countdown #timer div#days {
color: #FF2D55;
}
.event-countdown #timer div#hours {
color: #00acee;
}
.event-countdown #timer div#hours span {
right: -50px;
margin-top: -12px;
}
.event-countdown #timer div#minutes {
color: #ffffff;
}
.event-countdown #timer div#minutes span {
right: -55px;
margin-top: -12px;
}
.event-countdown #timer div#seconds {
color: #efa506;
}
.event-countdown #timer div#seconds span {
right: -60px;
margin-top: -12px;
}
.event-countdown #timer span {
display: block;
color: #ffffff;
margin-top: 0;
font-size: 14px;
font-weight: 500;
position: absolute;
right: -40px;
top: 50%;
text-transform: uppercase;
transform: rotate(-90deg);
margin-top: -10px;
letter-spacing: 2px;
}

.countdown1 {
bottom: 15px !important;
}
.countdown1 #timer div {
font-size: 60px;
}
.countdown1 #timer span {
display: inline-block;
font-size: 14px;
position: initial;
transform: rotate(0deg);
}

@keyframes ripple {
0%, 35% {
  transform: scale(0);
  opacity: 1;
}
50% {
  transform: scale(1.5);
  opacity: 0.8;
}
100% {
  opacity: 0;
  transform: scale(2);
}
}
.shape1 {
position: absolute;
left: 70px;
bottom: 70px;
z-index: -1;
animation: movebounce 5s linear infinite;
opacity: 0.6;
}

.shape2 {
position: absolute;
right: 70px;
bottom: 70px;
z-index: -1;
opacity: 0.6;
}

.shape3 {
position: absolute;
left: 70px;
top: 70px;
z-index: -1;
opacity: 0.6;
}

.shape4 {
position: absolute;
right: 100px;
top: 100px;
z-index: -1;
opacity: 0.6;
animation: movebounce 5s linear infinite;
}

.rotateme {
animation-name: rotateme;
animation-duration: 40s;
animation-iteration-count: infinite;
animation-timing-function: linear;
}

@keyframes rotateme {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}
@keyframes movebounce {
0% {
  transform: translateY(0px);
}
50% {
  transform: translateY(20px);
}
100% {
  transform: translateY(0px);
}
}
@keyframes colorchange {
0% {
  color: #FF2D55;
}
25% {
  color: #ffffff;
}
50% {
  color: red;
}
75% {
  color: #00acee;
}
0% {
  color: #efa506;
}
}
.home-slides .swiper-button-next, .home-slides .swiper-button-prev {
width: 50px;
height: 50px;
line-height: 50px;
background-color: #00acee;
color: #ffffff;
transition: 0.5s;
opacity: 0.7;
}
.home-slides .swiper-button-next::after, .home-slides .swiper-button-prev::after {
font-size: 25px;
}
.home-slides .swiper-button-next:hover, .home-slides .swiper-button-prev:hover {
background-color: #FF2D55;
color: #ffffff;
}
}

/* Untuk desktop */
@media (width> 1024px) {
.main-banner-content h1 {
  color: #ffffff;
  margin: 0;
  font-weight: 900;
  font-size: 65px;
}

.main-banner {
  height: 100vh;
  position: relative;
  z-index: -1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
  
}
.main-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* background: #6f0000;
  opacity: 0.8;
  mix-blend-mode: multiply; */
}

.slideshow-banner::before {
  z-index: 1;
}
.slideshow-banner .main-banner-content {
  margin-top: 50px;
  
}
.slideshow-banner .main-banner-content::before {
  display: none;
}
.slideshow-banner .slideshow {
  z-index: -1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.slideshow-banner .slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  backface-visibility: hidden;
  animation: imageAnimation 24s linear infinite 0s;
}
.slideshow-banner .slideshow li:nth-child(2) span {
  animation-delay: 6s;
}
.slideshow-banner .slideshow li:nth-child(3) span {
  animation-delay: 12s;
}
.slideshow-banner .slideshow li:nth-child(4) span {
  animation-delay: 18s;
}
.slideshow-banner .event-countdown {
  position: relative;
  right: 0;
  bottom: 0;
  text-align: left;
}
.slideshow-banner .event-countdown #days {
  margin-left: 0;
}

.video-banner {
  overflow: hidden;
}
.video-banner .video-background {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  z-index: -2;
}
/*   
.item-bg1 {
  background-image: url("/images/main-bg1.jpg");
}

.item-bg2 {
  background-image: url("/images/main-bg2.jpg");
}

.item-bg3 {
  background-image: url("/images/slider-bg1.jpg");
}

.item-bg4 {
  background-image: url("/images/slider-bg2.jpg");
}

.item-bg5 {
  background-image: url("/images/slider-bg3.jpg");
}
*/
.main-banner-content {
  max-width: 850px;
  position: relative;
  z-index: 1;
  padding-bottom: 200px;
}
.main-banner-content::before {
  content: "";
  position: absolute;
  top: -75px;
  width: 100%;
  max-width: 380px;
  text-align: center;
  height: 500px;
  left: 18%;
  margin-left: -190px;
  z-index: -1;
  /* border: 18px solid rgba(104, 89, 222, 0.24); */
  /* box-sizing: border-box; */
  /* box-shadow: 0 60px 40px -40px rgba(0, 0, 0, 0.3); */
}

.main-banner-content h1 span {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #FF2D55 80%);
  background-size: 100px 1.2em !important;
}
.main-banner-content h1 b:nth-of-type(1) {
  color: #efa506;
}
.main-banner-content h1 b:nth-of-type(2) {
  color: #FF2D55;
}
.main-banner-content h1 b:nth-of-type(3) {
  color: #ffffff;
  animation: colorchange 20s;
}
.main-banner-content h1 b:nth-of-type(4) {
  color: #00acee;
}
.main-banner-content ul {
  padding: 0;
  margin: 25px 0 40px;
  list-style-type: none;
}
.main-banner-content ul li {
  color: #fff;
  margin-right: 20px;
  font-size: 17px;
  display: inline-block;
}
.main-banner-content ul li i {
  margin-right: 3px;
  color: #FF2D55;
}
.main-banner-content ul li:last-child {
  margin-right: 0;
}
.main-banner-content p {
  color: #efa506;
  font-size: 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.main-banner-content p span {
  color: #00acee;
}
.main-banner-content .button-box a {
  display: inline-block;
}
.main-banner-content .button-box .video-btn {
  margin-left: 30px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.main-banner-content .button-box .video-btn i {
  width: 50px;
  display: inline-block;
  height: 50px;
  line-height: 51px;
  text-align: center;
  background-color: #00acee;
  border-radius: 50%;
  font-size: 17px;
  margin-right: 8px;
  z-index: 1;
  position: relative;
  transition: 0.5s;
}
.main-banner-content .button-box .video-btn i::after {
  z-index: -1;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  animation: ripple 1.6s ease-out infinite;
  animation-delay: 3s;
  opacity: 0;
  border-radius: 50%;
  background: rgba(51, 102, 204, 0.5);
}
.main-banner-content .button-box .video-btn:hover, .main-banner-content .button-box .video-btn:focus {
  color: #00acee;
}
.main-banner-content .button-box .video-btn:hover i, .main-banner-content .button-box .video-btn:focus i {
  background-color: #FF2D55;
  color: #ffffff;
}
.main-banner-content.banner-content-center {
  margin: 50px auto 0;
  text-align: center;
}

.countdown-area {
  padding: 50px 0;
  background: #ffc341;
}
.countdown-area .event-countdown {
  position: relative;
  right: 0;
  bottom: 0;
  text-align: center;
}
.countdown-area .event-countdown #timer div {
  margin: 0 55px;
}
.countdown-area .event-countdown #timer div#seconds {
  color: #FF2D55;
}

.event-countdown {
  position: absolute;
  right: 10%;
  bottom: 20px;
  text-align: right;
}
.event-countdown #timer div {
  display: inline-block;
  font-size: 55px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 35px;
  position: relative;
}
.event-countdown #timer div#days {
  color: #FF2D55;
}
.event-countdown #timer div#hours {
  color: #00acee;
}
.event-countdown #timer div#hours span {
  right: -50px;
  margin-top: -12px;
}
.event-countdown #timer div#minutes {
  color: #ffffff;
}
.event-countdown #timer div#minutes span {
  right: -55px;
  margin-top: -12px;
}
.event-countdown #timer div#seconds {
  color: #efa506;
}
.event-countdown #timer div#seconds span {
  right: -60px;
  margin-top: -12px;
}
.event-countdown #timer span {
  display: block;
  color: #ffffff;
  margin-top: 0;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: -40px;
  top: 50%;
  text-transform: uppercase;
  transform: rotate(-90deg);
  margin-top: -10px;
  letter-spacing: 2px;
}

.countdown1 {
  bottom: 15px !important;
}
.countdown1 #timer div {
  font-size: 60px;
}
.countdown1 #timer span {
  display: inline-block;
  font-size: 14px;
  position: initial;
  transform: rotate(0deg);
}

@keyframes ripple {
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
.shape1 {
  position: absolute;
  left: 70px;
  bottom: 70px;
  z-index: -1;
  animation: movebounce 5s linear infinite;
  opacity: 0.6;
}

.shape2 {
  position: absolute;
  right: 70px;
  bottom: 70px;
  z-index: -1;
  opacity: 0.6;
}

.shape3 {
  position: absolute;
  left: 70px;
  top: 70px;
  z-index: -1;
  opacity: 0.6;
}

.shape4 {
  position: absolute;
  right: 100px;
  top: 100px;
  z-index: -1;
  opacity: 0.6;
  animation: movebounce 5s linear infinite;
}

.rotateme {
  animation-name: rotateme;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes colorchange {
  0% {
    color: #FF2D55;
  }
  25% {
    color: #ffffff;
  }
  50% {
    color: red;
  }
  75% {
    color: #00acee;
  }
  0% {
    color: #efa506;
  }
}
.home-slides .swiper-button-next, .home-slides .swiper-button-prev {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #00acee;
  color: #ffffff;
  transition: 0.5s;
  opacity: 0.7;
}
.home-slides .swiper-button-next::after, .home-slides .swiper-button-prev::after {
  font-size: 25px;
}
.home-slides .swiper-button-next:hover, .home-slides .swiper-button-prev:hover {
  background-color: #FF2D55;
  color: #ffffff;
}
}


